<script setup lang="ts">
import { subject } from '@casl/ability';
import { useAppAbility } from '~/composables/useAppAbility';

const { can } = useAppAbility();

const workspaceStore = useWorkspaceStore();
const { workspace, subscription, subscriptionType } =
  storeToRefs(workspaceStore);

const { data: stripeSession, pending } = await useAsyncData(
  'stripeSession',
  () => $api(`/api/stripe/${workspace.value.id}`),
  {
    watch: [workspace],
  },
);
</script>
<template>
  <UButton
    v-if="can('manage', subject('Subscription', subscription))"
    variant="ghost"
    icon="i-ph-arrow-right"
    trailing
    size="xl"
    :loading="pending"
    @click="navigateTo(stripeSession?.url, { external: true })"
    >Manage Plan
  </UButton>
  <UButton
    v-else-if="subscriptionType === 'enterprise'"
    variant="ghost"
    icon="i-ph-envelope"
    trailing
    size="xl"
    to="mailto:sales@respell.ai"
    >Contact Us
  </UButton>
</template>
