<script setup lang="ts">
import type { Member } from '@respell/database';
import { config } from '@respell/utils/config';
import { useScriptTag } from '@vueuse/core';
import BillingInsights from '~/components/account/BillingInsights.vue';
import ManagePlanButton from '~/components/subscription/ManagePlanButton.vue';
import { useAppAbility } from '~/composables/useAppAbility';
import { subscriptionTypeToDisplayName } from '~/util/constants';

const { can } = useAppAbility();

const workspaceStore = useWorkspaceStore();
const { workspace, subscription, subscriptionType, workspaceMembers } =
  storeToRefs(workspaceStore);

await useAsyncCache('workspaceMembers', () =>
  workspaceStore.loadWorkspaceMembers(),
);

const { data: stripeSession, pending } = await useAsyncData(
  'stripeSession',
  () => $api(`/api/stripe/${workspace.value.id}`),
  {
    watch: [workspace],
  },
);

useScriptTag(
  'https://js.stripe.com/v3/pricing-table.js',
  // on script tag loaded.
  (el: HTMLScriptElement) => {
    // do something
  },
);

const workspaceAdmins = computed(() => {
  return workspaceMembers.value.filter(
    (member: Member) => member.role === 'admin',
  );
});

const uniqueAdmins = useArrayUnique(
  workspaceAdmins.value,
  (memberA: Member, memberB: Member) => memberA.userId === memberB.userId,
);

const uniqueUsers = useArrayUnique(
  workspaceMembers.value,
  (memberA: Member, memberB: Member) => memberA.userId === memberB.userId,
);

const pricingTableId = config.STRIPE_PRICING_TABLE_ID;
</script>
<template>
  <NuxtLayout name="account" :containerless="true">
    <p class="main-title mb-8">Plans & Billing</p>
    <div class="border border-gray-200 rounded-xl">
      <div class="flex justify-between items-center p-4">
        <div>
          <p class="body-sm dimmed">Current plan:</p>
          <p class="heading-md font-bold mt-2 mb-2">
            {{
              subscriptionTypeToDisplayName[subscriptionType] ||
              subscriptionTypeToDisplayName['free']
            }}
          </p>
        </div>
        <ManagePlanButton />
      </div>
      <hr />
      <div class="flex justify-between items-center p-4">
        <div>
          <div class="body-sm dimmed">Admin(s):</div>
          <div class="heading-md font-bold mt-2 mb-2">
            {{ uniqueAdmins.length }}
          </div>
        </div>
        <div>
          <div class="body-sm dimmed">Member(s):</div>
          <div class="heading-md font-bold mt-2 mb-2">
            {{ uniqueUsers.length }}
          </div>
        </div>
        <UButton
          variant="ghost"
          icon="i-ph-arrow-right"
          trailing
          size="xl"
          @click="navigateTo('/account/members')"
          >Manage Members
        </UButton>
      </div>
    </div>
    <div v-if="subscriptionType === 'free'">
      <hr class="mt-8 mb-8" />
      <p class="heading-md font-bold mt-2 mb-2">All Plans</p>
      <div class="flex items-center justify-center">
        <span class="text-accent">Save 20% annually</span>
      </div>
      <stripe-pricing-table
        :publishable-key="config.STRIPE_PUBLIC_KEY"
        :pricing-table-id="pricingTableId"
        :client-reference-id="workspace?.id"
      />
    </div>
    <div v-if="subscriptionType === 'enterprise'" class="mt-8">
      <BillingInsights />
    </div>
  </NuxtLayout>
</template>
